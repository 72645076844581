

.landing-header-desktop {
    animation: nav-load-in 1s ease;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}

.landing-header-mobile {
    display: none;
}

#landing-header-invidstor {
    float: left;
    color: white;
    margin-left: 4%;
    width: fit-content;
}

#landing-header-button {
    color: white;
    width: 100px;
    background: none;
    height: 40px;
    width: fit-content;
    font-size: 20px;
}

.landing-header-navigation-fullscreen {
    float: right;
    position: relative;
    top: 30px;
    right: 4%;
    width: fit-content;
    height: fit-content;
    position: relative;
    display: flex;
}

.landing-header-button {
    text-decoration: none;
    color: white;
    margin-left: 25px;
    transition: .1s ease;
}

.landing-header-button-active {
    background-color: white;
    color: #00758f;
    font-weight: 500;
    padding: 3px 10px 3px 10px;
    border-radius: 10px;
    position: relative;
    top: -3px;
}

.landing-header-button-active {

}








.landing-page-home-background {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 1000px;
    z-index: 1;
}

.landing-page-home-top-text-container {
    color: white;
    position: absolute;
    top: 43%;
    left: 32%;
    transform: translate(-50%, -50%);
    transition: 2s ease;
    animation: home-load-in 2s ease-out;
    z-index: 9;
}

.landing-page-home-top-text-container h2 {
    font-size: 40px;
}

.landing-page-home-top-text-button {
    z-index: 9;
    border: none;
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px 4px 20px 4px;
    padding: 10px 40px 10px 40px;
    background-color: #f9b233;
    margin: 0px;
}

.landing-page-home-top-text-button:hover {
    transition: 1s ease;
    color: rgb(70, 70, 70);
}

.landing-page-home-curve {
    transition: 2s ease;
    opacity: .05;
    position: absolute;
    width: 110%;
    z-index: 2;
    bottom: -8%;
}

#curve1 {
    width: 200%;
    left: -20%;
}


#landing-page-home-desksvg {
    position: absolute; 
    top: 50%;
    transform: translate(-50%, -50%);
    left: 70%;
    height: 50vh;
    z-index: 3;
}

#landing-page-home-lightbulbs {
    position: absolute;
    top: 150px;
    left: 0px;
    height: 150px;
    z-index: 3;
}


.landing-page-home-3row-container {
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, #3a47d500 110%);
    text-align: center;
}

.landing-page-home-3row {
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: fit-content;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 1;
    padding: 0px 0px 50px 0px;
}


.landing-page-home-3row-div-active{
    animation: nav-load-in 1s ease;
    opacity: 1 !important;
}

.landing-page-home-3row-div {
    overflow: hidden;
    height: 300px;
    width: 300px;
    padding: 10px;
    margin: 0px 2% 0px 2%;
    text-align: center;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;     
    position: relative;
    z-index: 2;
    opacity: 0;
}

.landing-page-home-3row-svg {
    width: 150px;
    height: 150px;
}

.landing-page-home-3row-svg-container {
    background-color: rgba(0,0,0,.1);
    border-radius: 100%;
    margin: 0 auto;
    width: fit-content;
    padding: 35px;

}

.landing-page-home-3row-div h3 {
    letter-spacing: 5px;
    padding: 10px 0px 10px 0px;
    color: white;
    font-weight: 800;
}






.landing-page-home2-background {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 0px 50px;
}

.landing-page-home2-container {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    opacity: 0;
}

.landing-page-home2-container-active {
    animation: nav-load-in 2s ease;
    opacity: 1 !important;
}

.landing-page-home2-body {
    width: fit-content;
    height: fit-content;
    color: white;
    text-align: left;
    display: flex;
    flex-direction: column;
    max-width: 90%;

}

.landing-page-home2-body li {
    font-size: 20px;
    list-style:  none;
    margin-bottom: 15px;
}

.landing-page-home2-body h1 {
    border-bottom: 1px solid white;
    padding-bottom: 3px;
}

#landing-page-home2-investsvg {
    height: 300px;
}





.landing-page-home3-container {
    height: fit-content;
    max-width: 1300px;
    margin: 0 auto;
    opacity: 0;
}

.landing-page-home3-container-active {
    opacity: 1 !important;
    animation: 2s load-in-left ease;
}

.landing-page-home3-body {
    margin: 40px;
    color: white;
    padding: 50px;
    max-width: 800px;
    background-color: rgba(51, 51, 51, 0.253);
    border-radius: 10px;
}




.landing-page-home4-container {
    height: fit-content;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    
}

.landing-page-home4-container-active {
    opacity: 1 !important;
    animation: 2s load-in-right ease;
}

.landing-page-home4-body {
    margin: 40px;
    color: white;
    padding: 50px;
    max-width: 800px;
    background-color: rgba(51, 51, 51, 0.253);
    border-radius: 10px;
}

.landing-page-home4-body h1 {
    text-align: right;
}






.landing-page-footer {
    color: White;
    width: 100%;
    background-color: rgb(24, 24, 34);
    text-align: center;
}

.landing-page-footer-container {
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 700px;
}

.landing-page-footer > p {
    color: WHITE;
    padding: 100px;
    margin: 0px;
}

.landing-page-footer-column {
    max-width: 90%;
    margin: 20px;
    width: 250px;
    text-align: left;
}

.landing-page-footer-column h3 {
    border-bottom: 2px solid rgba(255, 255, 255, 0.418);
    text-align: left;
    padding-left: 4px;
    padding-bottom: 5px;
}

.landing-page-footer-nav-div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-evenly;
}


.landing-footer-button {
    color: white;
    text-decoration: none;
    margin: 3px;
    
}

.active-class {
    color: rgb(255, 255, 255);
    font-weight: 900;
}

@media screen and (min-height: 1440px) {
    #landing-page-home-desksvg {
        height: 25vw;
    }
}

/* the bottom margin on the 3 row doesnt work in this range before mobile and after desktop */
@media screen and (min-width: 700px) and (max-width: 900px) {
    .landing-page-home-3row-div {
        top: 0px;
        margin: 20px;
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 700px) {
    .landing-header-navigation-fullscreen {
        display: none;
    }
    .landing-header-navigation-mobile-button {
        display: contents;
    }
    .landing-page-home-3row-div {
        top: 0px;
        margin: 10px 0px 10px 0px;
    }
    .landing-page-home-background {
        height: 100vh;
    }
    .landing-page-home2-container {
        border-radius: 0px;
    }
    .landing-page-home-top-text-container {
        font-size: 30px;
        width: fit-content;
        height: fit-content;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
    }
    .landing-page-home4-body h1 {
        text-align: left;
    }
    .landing-page-home-top-text-container:hover {
        transform: translate(-50%, -50%)
    }
    .landing-page-home-top-text-container h2 {
        font-size: 30px;
    }
    .landing-page-home-curve { 
        width: 300%;
    }
    #landing-page-home-desksvg {
        display: none;
    }
    #landing-page-home-lightbulbs {
        top: 132px;
        height: 100px;
    }
    .landing-page-home2-background {
        padding: 40px 0px 40px 0px;
    }

    /* Mobile header */
    .landing-header-mobile {
        display: contents;

        #landing-header-mobile-button {
            position: fixed;
            font-size: 25px;
            margin: 0px;
            padding: 10px;
            border: none;
            background: rgb(255, 255, 255);
            border-radius: 10px;
            top: 15px;
            right: 20px;
            z-index: 6;

            #landing-header-mobile-button-hamburger {
                color: rgb(99, 99, 99);
            }
        }

        .landing-header-mobile-menu {
            position: fixed;
            left: 0px;
            width: 100%;
            background-color: rgb(24, 41, 58);
            transition: .6s ease;
            height: 300px;
            padding: 10px;
            z-index: 5;

            .landing-header-mobile-navigation {
                padding-top: 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .landing-header-mobile-button {
                    text-align: right;
                    color: white;
                    text-decoration: none;
                    font-size: 25px;
                    font-weight: 500;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 20px;
                    border-bottom: 2px solid rgba(255, 255, 255, 0.144);
                    padding: 0px 5px 8px 0px;
                }
            }
        }
    }
}

@keyframes home-load-in {
    from {
        opacity: 0;
        transform: translate(-40%, -50%);
    } to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

@keyframes fade-from-right {
    from {
        transform: translate(100px);
    } to {
    }
}

@keyframes nav-load-in {
    from {
        opacity: 0;
        transform: translate(0%, -30%);
    }
}

@keyframes load-in-left {
    from {
        opacity: 0;
        transform: translate(-20%, 0%)
    }
}

@keyframes load-in-right {
    from {
        opacity: 0;
        transform: translate(20%, 0%)
    }
}