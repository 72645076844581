@import '../../SCSS/media';

.dashboard-bet-container {
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: InitialLoad 2s ease;
}

.dashboard-bet-thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
        font-size: 20px;
        margin: 5px;
        width: 100%;

        @include phone {
            font-size: 15px;
        }
    }

    .title {

      a {
        text-decoration: none;
        font-size: 1rem;
        width: fit-content;
        font-weight: bold;
        margin: 15px auto;
        background: linear-gradient(45deg, #00d2ff 0%, #3a47d5 100%);
        color: white;
        border-radius: 20px;
        padding: 5px 15px;
        margin: 15px auto;
        display: block;
      }
    }

    .date {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      p {

        font-weight: 300;
        width: fit-content;
        color: #00d2ff;
        background-color: rgba(0, 0, 0, 0.116);
        border-radius: 5px;
        padding: 5px;
        margin: 0px;

        span {
          font-weight: bold;
          color: #00d2ff;
        }

      }
      
    } 
    
    #dashboard-bet-thumbnail {
        object-fit: cover;
        height: 140px;
        width: 250px;
        margin: 20px;
        min-height: 140px;
        border: 1px solid white;
        border-radius: 10px;
    }
}

.dashboard-bet-bottom-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: left;
    border-radius: 30px;

    @include phone {
      min-width: 90%;
    }

    h3 {
      width: 100%;
      text-align: center;
      padding: 15px 0px;
      margin: 0px 0px;
    }

    .dashboard-bet-stats-container {
        min-width: 500px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.185);
        margin: 0px;
        border-radius: 30px;
        
        @include phone {
            min-width: 0px;
        }
      
        p {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 12px;
            font-weight: bold;
            letter-spacing: 1.2px;

            @include phone {
                font-size: 12px;
                letter-spacing: 0px;
            }
        }
    }
    
    .dashboard-bet-slider-container {
        height: 50px;
        display: flex;
        align-content: center;
        justify-content: center;
        
        .dashboard-bet-slider-button {
            width: 90%;
            margin: 3.3px 0;
            background-color: transparent;
            -webkit-appearance: none;
            
            &:focus {
              outline: none;
            }
            &::-webkit-slider-runnable-track {
              background: rgb(168, 168, 168);
              border: 0;
              border-radius: 25px;
              width: 100%;
              height: 12px;
              cursor: pointer;
            }
            &::-webkit-slider-thumb {
              margin-top: -3.3px;
              width: 33px;
              height: 20px;
              background: #ffffff;
              border-radius: 50px;
              cursor: pointer;
              -webkit-appearance: none;
              transition: .3s; 
            }
            &:hover::-webkit-slider-thumb {
              background: #00aeff;
              transform: scale(1.5);
            }
            &:focus::-webkit-slider-runnable-track {
              background: rgb(185, 185, 185);
            }
            &::-moz-range-track {
              background: rgb(199, 199, 199);
              border: 0;
              border-radius: 25px;
              width: 100%;
              height: 12px;
              transition: .3s; 
              cursor: pointer;
            }
            &::-moz-range-thumb {
              width: 33px;
              height: 20px;
              background: #ffffff;
              border-radius: 50px;
              cursor: pointer;
              transition: .3s;
            }
            &:hover::-moz-range-thumb {
              background: #00aeff;
            }
            &::-ms-track {
              background: transparent;
              border-color: transparent;
              border-width: 4.3px 0;
              color: transparent;
              width: 100%;
              height: 8.4px;
              cursor: pointer;
            }
            &::-ms-fill-lower {
              background: #cecece;
              border: 0;
              border-radius: 50px;
            }
            &::-ms-fill-upper {
              background: rgba(0, 0, 0, 0.2);
              border: 0;
              border-radius: 50px;
            }
            &::-ms-thumb {
              width: 33px;
              height: 15px;
              background: #ffffff;
              border: 1.1px solid rgba(0, 0, 0, 0.4);
              border-radius: 50px;
              cursor: pointer;
              margin-top: 0px;
              /*Needed to keep the Edge thumb centred*/
            }
            &:focus::-ms-fill-lower {
              background: rgba(173, 173, 173, 0.2);
            }
            &:focus::-ms-fill-upper {
              background: #a8a8a8;
            }
        }
    }

    .dasboard-bet-submit-button {
      border: 0px;
      color: white;
      font-weight: bold;
      min-width: 500px;
      height: 40px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: linear-gradient(45deg, #00d2ff 0%, #3a47d5 100%);
      margin: 0px;
      border-radius: 30px;
      overflow: hidden;

      &:focus {
        outline: none;
      }

      @include phone {
        min-width: 0px;
        width: 100%;
      }


      &:hover {
        h3 {
          transform: scale(1.2);
        }
      }

      h3 {
        padding: 5px;
        width: fit-content;
        border-radius: 5px;
        transition: 1s ease;
        font-weight: bold;
        letter-spacing: 1.2px;
      }
    }

}
@keyframes InitialLoad {
    from {
        opacity: 0;
        transform: translateY(-100px)
    } 
}