@import './SCSS/media';

#dashboard-invest-spinner {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: white;
    font-size: 50px;
    animation: 1s spinner infinite linear ;
}

.dashboard-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    background-color: rgb(14, 24, 34);
}


.dashboard-left {
    width: 20%;
    max-width: 300px;
    min-width: 200px;
    min-height: 100vh;
    font-size: 20px;
    background: rgb(27, 47, 66);
    animation: left-load-in 1.5s ease;
    

    @include phone {
        display: none;
    }
    
    .dashboard-text {
        margin-top: 15px;
        text-align: center;
        color: white;

        h1 {
            margin: 0px;
            background: linear-gradient(45deg, #00d2ff 0%, #3a47d5 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        p {
            margin: 0px;
        }
    }


    header {
        height: fit-content;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        opacity: 0;
        animation: fadeIn 1s ease forwards;
        animation-delay: 1.3s;

        a {
            transition: .5s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            color: rgb(219, 219, 219);
            text-decoration: none;
            width: 100%;

            &:hover {
                background-color: rgb(24, 41, 58);
            }
        }

        .dashboard-icons {
            color: grey;
            border-radius: 100%;
            padding: 8px;
            position: absolute;
            left: 10px;
            font-size: 17px;
        }

        .dashboard-header-active {
            font-weight: bold;
            animation: clipcircle .5s ease-out;
            background-color: rgb(14, 24, 34) !important;
            height: 60px;

            .dashboard-icons {
                font-size: 25px;
                box-shadow: none;
                color: rgb(24, 158, 196)
            }
        }
    }

    footer {
        position: absolute;
        bottom: 0px;
        width: 20%;
        max-width: 300px;
        min-width: 200px;
        opacity: 0;
        animation: fadeIn 1s ease forwards;
        animation-delay: 1.3s;

        @include phone {
            display: none;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            color: rgb(219, 219, 219);
            text-decoration: none;
        }

        .dashboard-back-button {
            position: absolute;
            left: 10px;
            font-size: 20px;
            padding: 8px;
            color: grey;
        }
    }
}


.dashboard-right {
    min-height: fit-content;
    display: flex;
    flex: 1;
    flex-shrink: -1;
    flex-grow: -1;

    @include phone {
        min-width: 100vw;
        min-height: 100vh;
    }

    .dashboard-right-container {
        position: relative;
        width: 95%;
        padding: 5% 5%;
        box-sizing: border-box;
        height: 95%;
        overflow: hidden;
        margin: auto auto;
        background: rgba(27, 47, 66, 0.418);
        border-radius: 20px;
        color: white;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.300);
    }
}


@keyframes clipcircle {
    from {
        clip-path: circle(0% at 100% 50%);
    } to {
        clip-path: circle(150% at 100% 50%);
    }
}

@keyframes left-load-in {
    from {
        transform: translate(-100%, 0)
    }
}

@keyframes spinner {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}



@media screen and (max-width: 700px) {
    .dashboard-container {
        flex-wrap: nowrap;
        align-items: none;
    }
    .dashboard-header {
        margin-top: 30px;
        font-size: 20px;
    }
}