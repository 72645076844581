.myProfileRow {
    color: #00d2ff;
    border-bottom: 1px solid rgba(255,255,255,.1);
    animation: fadeIn 2s ease;
}



.myProfileSpan {
    color: white;
    font-weight: 300;
}

.myProfileEditingRow {
    color: #00d2ff;
    display: flex;
    border-bottom: 1px solid rgba(255,255,255,.1);
}

.myProfileEditingRow input{
    background: rgba(0, 0, 0, 0.103);
    color: white;
    font-size: 20px;
    border: none;
    border: 1px solid rgb(27, 47, 66);
    margin: 10px;
    padding-left: 30px;
    width: 100%;
    text-align: left;
    border-radius: 30px;
}

.myProfileEditingRow input:focus {
    outline: none;
}

.myProfileEditingButton {
    border-radius: 15px;
    float: right;
    margin: 3%;
    font-size: 15px;
    background-color: rgba(0,0,0,.1);
    padding: 10px 30px 10px 30px;
    border: none;
    color: #00d2ff;
}