

.login-page-container{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color:rgb(22, 38, 53);

}

.login-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(27, 47, 66);
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.247);
    overflow: hidden;
    div{
        width: 300px;
    }
    h3 {
        color: white;
        font-size: 15px;
        font-weight: 600;
        margin: 15px 0px 25px 0px;

    }
    .login-form-maintext {
        color: white;
        text-align: center;
        h1 {
            margin: 0px;
            color: #00d2ff;
            margin: 5px 0px 5px 0px;
        }
        p {
            margin: 5px 0px 5px 0px;
        }
    }
    .login-form {
        width: 350px;
        background-color: rgb(22, 38, 53);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.288);
    
        input {
            transition: 1s ease;
            width: 80%;
            background: none;
            color: white;
            font-size: 20px;
            border: none;
            border-bottom: 1px solid rgb(27, 47, 66);
            margin: 15px 0px 15px 0px;
            padding: 10px;
            animation: input-load 1s ease;
    
            &:focus {
                outline: rgb(22, 38, 53);
                outline-style: double;
            }
            &::placeholder {
                color: rgba(255, 255, 255, 0.692)
            }
        }
        .login-page-registerbutton {
            background: none;
            font-size: 13px;
            color: grey;
            border: none;
            text-decoration: underline;
            margin: 0px 0px 15px 0px;

            &:focus {
                outline: none;
            }
        }
        #login-page-submit {
            transition: 1s;
            margin: 20px;
            font-size: 15px;
            font-weight: 800;
            letter-spacing: 2px;
            width: 90%;
            background: linear-gradient(45deg, #00d2ff 0%, #3a47d5 100%);
            border: none;
            height: 30px;
            color: white;
            border-radius: 10px;
            &:focus {
                outline: none;
            }
        }
    }
}

@keyframes input-load {
    from {
        opacity: 0;
        transform: translate(-10px, 0px)
    } to {
        opacity: 1;
    }
}
