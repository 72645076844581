.modal-container {
    animation: fadeIn 1s ease;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(17, 17, 17);
    width: fit-content;
    height: fit-content;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 0px 40px 20px rgba(0, 0, 0, 0.2);
}
